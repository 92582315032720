import './App.css';

var DoiList = require('./Doi');

var moment = require('moment');
var now = moment();

function App() {
  return (
    <div className="App">
      <div className="d-flex flex-column align-items-center justify-content-center bg-dark text-white py-5 site-hero">
        <h1 className="display-6 text-muted">Mikä viikko nyt on?</h1>
        <h2 className="display-1 fw-bold">{now.format('W')}</h2>
        <div>ma {now.startOf('isoWeek').format('DD.MM.')} - su {now.endOf('isoWeek').format('DD.MM.YYYY')}</div>
      </div>
      <div className="py-5 text-center bg-light text-dark">
        <div className="container">
          <div className="row row-cols-1 row-cols-md-5 g-4">
            {DoiList(5).map(d => <div className="feature col" key={d.label}>{d.label} on <span className="from-now">{d.fromNow}</span></div>)}
          </div>
        </div>
      </div>
      <div className="py-5 text-center bg-vko-1 text-white">
        <div className="container">
          <div className="row g-4">
            <div className="feature col">
              <h4>Maanantai</h4>
              <p>Sanan etymologia on muinaisskandinaavisessa sanassa mánandagr, kuupäivä.</p>
            </div>
            <div className="feature col">
              <h4>Tiistai</h4>
              <p>Sanan etymologia on ruotsin sanassa tisdag. What a turn off.</p>
            </div>
            <div className="feature col">
              <h4>Keskiviikko</h4>
              <p>Nimi johtuu siitä, että se on keskellä vanhaa, sunnuntaista alkavaa viikkoa.</p>
            </div>
            <div className="feature col">
              <h4>Torstai</h4>
              <p>Sana on tullut todennäköisimmin ruotsin kielen sanasta torsdag (Tor-jumalan päivä).</p>
            </div>
            <div className="feature col">
              <h4>Perjantai</h4>
              <p>Sana tulee Freija- tai Frigg-nimisen aasajumalattaren nimestä.</p>
            </div>
            <div className="feature col">
              <h4>Lauantai</h4>
              <p>Sana tulee muinaisgermaanisesta sanasta laugr, joka tarkoittaa kylpyä.</p>
            </div>
            <div className="feature col">
              <h4>Sunnuntai</h4>
              <p>Päivä on nimetty suurimman taivaankappaleen eli Auringon mukaan.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 text-center">
        <div className="container">
          <div className="row g-4">
            <div className="feature col">
            Viikko on seitsemän vuorokauden mittainen ajanjakso, joka on todennäköisesti lähtöisin Babyloniasta. Päivät nimettiin "seitsemän planeetan" eli Auringon, Kuun, ja viiden paljaalla silmällä erottuvan planeetan mukaan.
            </div>
            <div className="feature col">
              <ul>
                <li>Maanantai: Kuu</li>
                <li>Tiistai: Mars</li>
                <li>Keskiviikko: Merkurius</li>
                <li>Torstai: Jupiter</li>
                <li>Perjantai: Venus</li>
                <li>Lauantai: Saturnus</li>
                <li>Sunnuntai: Aurinko</li>
              </ul>
            </div>
            <div className="feature col">
            Useimmissa Euroopan maissa viikon ajatellaan alkavan maanantaista, Suomessakin alkaen vuodesta 1973. Sen sijaan esimerkiksi Yhdysvalloissa viikon ensimmäisenä päivänä pidetään edelleen sunnuntaita ja viimeisenä lauantaita. Mittään järkeä.
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 text-center text-muted">
        <div className="container">
          <footer>
              <p><a href="/">vko.fi</a>: Vastaus kysymykseen <em>"Mikä viikko?"</em> jo vuodesta 2014.</p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default App;
