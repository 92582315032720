var moment = require('moment');

moment.updateLocale('en', {
	relativeTime : {
		future: "in %s",
		past:   "%s sitten",
		s:  "sekuntia",
		m:  "yksi minuutti",
		mm: "%d minuuttia",
		h:  "tunti",
		hh: "%d tuntia",
		d:  "yksi päivä",
		dd: "%d päivää",
		M:  "yksi kuukausi",
		MM: "%d kk",
		y:  "yksi vuosi",
		yy: "%d vuotta"
	}
});

var dates = [
	{
		label: 'Vappuun',
		moment: moment('1.5.', 'DD.MM.'),
	},
	{
		label: 'Jouluun',
		moment: moment('24.12.', 'DD.MM.'),
	},
	{
		label: 'Juhannukseen',
		moment: moment('24.6.', 'DD.MM.'),
	},
	{
		label: 'Aprillipäivään',
		moment: moment('1.4.', 'DD.MM.'),
	},
	{
		label: 'Naistenpäivään',
		moment: moment('8.3.', 'DD.MM.'),
	},
	{
		label: 'Ystävänpäivään',
		moment: moment('14.2.', 'DD.MM.'),
	},
	{
		label: 'Isänpäivään',
		moment: moment('1.11.', 'DD.MM.').add(1, 'week').endOf('isoWeek').hour(0).minute(0).second(0),
	},
	{
		label: 'Äitienpäivään',
		moment: moment('1.5.', 'DD.MM.').add(1, 'week').endOf('isoWeek'),
	},
];

module.exports = function(limit) {
	var now = new Date();
	dates.map((d) => {
		d.moment > now || d.moment.add(1, 'year');
		d.fromNow = d.moment.fromNow(true);
        return d;
	});
	dates.sort((a, b) => {
		return a.moment.isBefore(b.moment) ? -1 : (a.moment.isAfter(b.moment) ? 1 : 0);
	});
	0 < limit && dates.splice(limit);
	return dates;
};